import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'
import moment from 'moment'
// assets
// import Ilojas from '../images/home/header/icon-lojas.svg'

// Components
import MenuDesktop from '../components/Ofertas/Menu'
import Oferta from '../components/Ofertas/oferta'

// scss
import Packery from 'react-packery'
import './ofertasSite.scss'

const OfertasDesktop = (data) => {
  const MasonryOptions = {
    percentPosition: true,
    originLeft: true
  }

  // const carregaImagens = () => {
  //   document.getElementsByClassName('tamanho-grid')[0].style.display = 'block'
  // }

  return (
    <>
      {
        <section className={'my-lg-2 p-0'}>
          <header className="container pb-lg-2 d-flex justify-content-between align-items-center">
            <h2 className="font-size-2 text-center text-lg-left">
              Ofertas válidas até <span className="text-red">{moment.utc(data.dataFinal).tz('America/Sao_Paulo').format('DD/MM/YYYY')}</span> ou até durarem os estoques, somente em lojas físicas.
            </h2>
          </header>
          {
            data.tabloide && data.tabloide.length === 0 && (<div className="d-block mx-auto pt-2" style={{ position: 'relative' }}>
              <p className="text-center h5">NENHUMA OFERTA ENCONTRADA NESTA CATEGORIA!</p>
            </div>
            )
          }

          <div className="">
            <Packery
              className="tamanho-grid justify-content-center "
              // onImagesLoaded={carregaImagens}
              options={MasonryOptions}>
              <div className="grid-sizer"></div>
              {(data.tabloide && data.tabloide.length > 0) && data.tabloide.map(oferta => {
                return (
                  <div key={oferta.Id}>
                    <Oferta
                      oferta={oferta}
                      handleClick={data.handleFavorito}
                      idTarifa={data.loja.tariff.id}
                    />
                  </div>
                )
              })}
            </Packery>
          </div>
        </section>
      }
    </>
  )
}

export default function OfertasSite ({ data }) {
  const carregaOfertas = () => {
    if (typeof window !== 'undefined') {
      if (!window.localStorage) {
        setLocalStorage()
      }
      const calculaBaseTamanho = () => {
        const base = (window.outerWidth > 1250) ? 1250 : window.outerWidth
        const columns = Math.floor(base / 250) === 1 ? 2 : Math.floor(base / 250)
        return base / columns
      }
      if (window.localStorage) {
        const favoritos = JSON.parse(window.localStorage.getItem('favoritos') || '[]')
        const baseTamanho = calculaBaseTamanho()

        return data.tabloide && data.tabloide.offer.map(o => Object.assign(o, {
          like: favoritos.findIndex(f => f === o.id) > -1,
          style: {
            width: [2, 3].includes(o.format) ? baseTamanho * 2 : baseTamanho,
            height: [1, 3].includes(o.format) ? baseTamanho * 2 : baseTamanho
          }
        }))
      }
    }
  }
  // eslint-disable-next-line no-unused-vars
  const [categoria, setCategoria] = useState(carregaCategorias())
  const [tabloide, setTabloide] = useState(carregaOfertas())
  useEffect(() => {
    // data.tabloide && setTabloide()
    const { loja } = data
    window.localStorage.setItem('OFERTAS_SEMANA', loja.slug)
    let TagManagerArgs
    if (loja.tariff !== null) {
      TagManagerArgs = {
        gtmId: 'GTM-TF6VN34',
        lojaLayer: {
          lojaNumero: loja.storeNumber,
          lojaTarifa: loja.tariff.id,
          // lojaTipo: loja.Tipo,
          lojaEndereco: loja.address,
          lojaCidade: loja.city,
          lojaEstado: loja.uf,
          interface: 'site'
        }
      }
    } else {
      TagManagerArgs = {
        gtmId: 'GTM-TF6VN34',
        dataLayer: {
          lojaNumero: loja.storeNumber,
          // lojaTipo: loja.Tipo,
          lojaEndereco: loja.address,
          lojaCidade: loja.city,
          lojaEstado: loja.uf,
          interface: 'site'
        }
      }
    }

    TagManager.dataLayer(TagManagerArgs)
  }, [])

  function carregaCategorias () {
    return data.categorias.nodes.map(o => {
      const ofertasCat = separeTabloideToCategory(o)
      if (ofertasCat) {
        o.exibe = !!(ofertasCat.length > 0 || (o.type === 'FAVORITOS' || o.type === 'TODOS'))
      }
      return o
    })
  }

  function filtroOfertas (cat) {
    setTabloide(separeTabloideToCategory(cat))
  }

  function separeTabloideToCategory (filter) {
    if (data.tabloide) {
      switch (filter.type) {
        case 'TODOS': {
          const filterOfertas = data.tabloide.offer.filter((item) => item.category?.type !== 'BANNER')
          const banner = data.tabloide.offer.filter(item => item.category?.type === 'BANNER')
          return [...banner, ...filterOfertas]
        }
        case 'NORMAL':
          return data.tabloide.offer.filter(item => (item.category?.id === filter.id))
        case 'TAG': {
          const tags = filter.tag.split(',')
          return data.tabloide.offer.filter(item => item.tag && item.tag.includes(tags.map((tag) => { return tag })))
        }
        case 'MARCA':
          return data.tabloide.offer.filter(item => item.brand && (item.brand.id.includes(filter.brand.map((brandF) => { return brandF.id }))))
        case 'FAVORITOS':
          return data.tabloide.offer.filter(item => (item.like))
        default: {
          return data.tabloide.offer.filter(item => (item.category?.id === filter.id))
        }
      }
    }
  }

  const setLocalStorage = () => {
    Object.defineProperty(window, 'localStorage', new function () {
      var aKeys = []; var oStorage = {}
      Object.defineProperty(oStorage, 'getItem', {
        value: function (sKey) { return sKey ? this[sKey] : null },
        writable: false,
        configurable: false,
        enumerable: false
      })
      Object.defineProperty(oStorage, 'key', {
        value: function (nKeyId) { return aKeys[nKeyId] },
        writable: false,
        configurable: false,
        enumerable: false
      })
      Object.defineProperty(oStorage, 'setItem', {
        value: function (sKey, sValue) {
          if (!sKey) { return }
          document.cookie = escape(sKey) + '=' + escape(sValue) + '; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/'
        },
        writable: false,
        configurable: false,
        enumerable: false
      })
      Object.defineProperty(oStorage, 'length', {
        get: function () { return aKeys.length },
        configurable: false,
        enumerable: false
      })
      Object.defineProperty(oStorage, 'removeItem', {
        value: function (sKey) {
          if (!sKey) { return }
          document.cookie = escape(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
        },
        writable: false,
        configurable: false,
        enumerable: false
      })
      Object.defineProperty(oStorage, 'clear', {
        value: function () {
          if (!aKeys.length) { return }
          for (var sKey in aKeys) {
            document.cookie = escape(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
          }
        },
        writable: false,
        configurable: false,
        enumerable: false
      })
      this.get = function () {
        var iThisIndx
        for (var sKey in oStorage) {
          iThisIndx = aKeys.indexOf(sKey)
          if (iThisIndx === -1) { oStorage.setItem(sKey, oStorage[sKey]) } else { aKeys.splice(iThisIndx, 1) }
          delete oStorage[sKey]
        }
        for (aKeys; aKeys.length > 0; aKeys.splice(0, 1)) { oStorage.removeItem(aKeys[0]) }
        for (var aCouple, iKey, nIdx = 0, aCouples = document.cookie.split(/\s*;\s*/); nIdx < aCouples.length; nIdx++) {
          aCouple = aCouples[nIdx].split(/\s*=\s*/)
          if (aCouple.length > 1) {
            oStorage[iKey = unescape(aCouple[0])] = unescape(aCouple[1])
            aKeys.push(iKey)
          }
        }
        return oStorage
      }
      this.configurable = false
      this.enumerable = true
    }())
  }

  const notificaFavorito = (oferta) => {
    if (!window.localStorage) {
      setLocalStorage()
    }
    tabloide.filter(offer => offer.id === oferta.id && (offer.like = oferta.like))
    const favoritos = tabloide.filter(t => (t.like)).map(o => (o.id))
    if (window.localStorage) {
      window.localStorage.setItem('favoritos', JSON.stringify(favoritos))
    }
  }

  function createDescritpion (services) {
    var description
    var Ifood = false
    var Rappi = false

    services.map((serv) => {
      if (serv.name === 'Ifood') {
        Ifood = true
      } else if (serv.name === 'Rappi') {
        Rappi = true
      }
    })

    if (Ifood || Rappi) {
      if (Rappi) {
        description = 'Acesse e confira as ofertas desta semana no DIA Supermercado. Loja Delivery: Rappi. Receba o que precisa com conforto e segurança.'
      }
      if (Ifood) {
        description = 'Acesse e confira as ofertas desta semana no DIA Supermercado. Loja Delivery: Ifood. Receba o que precisa com conforto e segurança.'
      }
      if (Ifood && Rappi) {
        description = 'Acesse e confira as ofertas desta semana no DIA Supermercado. Loja Delivery: Rappi e Ifood. Receba o que precisa com conforto e segurança.'
      }
    } else {
      description = `Acesse e confira as ofertas desta semana no DIA Supermercado | Loja: ${data.loja.uf} - ${data.loja.city} - ${data.loja.district} - ${data.loja.address} .`
    }
    return description
  }

  return (
    <>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="UTF-8" />
        <title>DIA Supermercados {data.loja.uf} - {data.loja.city} - {data.loja.district} - {data.loja.address}</title>
        <meta name="description" content={createDescritpion(data.loja.services)} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="DIA Supermercados"/>
      </Helmet>
      <MenuDesktop handleFilter={filtroOfertas} loja={data.loja} app={true} categorias={categoria} />

      {
        !data.tabloide
          ? (<div className="d-block mx-auto pt-2" style={{ position: 'relative' }}>
            <p className="text-center h5">NENHUMA OFERTA ENCONTRADA NESTA LOJA!</p>
          </div>)
          : (
            <>
              <OfertasDesktop tabloide={tabloide} loja={data.loja} handleFavorito={notificaFavorito} dataFinal={data.tabloide.finishDate}/>
              <p className="m-5">{data.tabloide.legalText}</p>
            </>
          )
      }
    </>
  )
}

export const query = graphql`
query ($idTarifa: String!, $ID: String!) {
  loja: cloudOfertasLoja(id: {eq: $ID}) {
    id
    slug
    address
    cep
    city
    district
    lat
    lng
    name
    storeNumber
    tariff {
      id
      name
    }
    reference
    status
    uf
    whatsapp
    mondayOpen
    mondayClose
    holidayOpen
    holidayClose
    sundayOpen
    sundayClose
    services {
      icon
      name
    }
  }
  categorias: allCloudOfertasCategoria(sort: {fields: sequence}) {
    nodes {
      name
      id
      sequence
      type
      brand {
        name
        id
      }
      tag
      icon {
        url
      }
    }
  }
  tabloide: cloudOfertasTabloide(tariff: {id: {eq: $idTarifa}}){
      offer {
        linkBanner
        tag
        id
        image {
          url
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
        format
        finishDate
        order
        category {
          sequence
          type
          id
          name
        }
        brand {
          name
          id
        }
      }
      legalText
      finishDate
      startDate
      tariff {
        id
        name
      }
  }
  site:site {
    siteMetadata {
      siteUrl
      facebookAppId
      author
      title
      siteName
      tagManagerId
      facebookAPIVersion
    }
  }
}`
